<template>
  <div class="d-flex align-center">
    <div
      style="
        width: 40%;
        margin-right: 10px;
      "
    >
      <v-tooltip
        top
        open-delay="300"
      >
        <template v-slot:activator="{ on, attrs }">
          <div
            style="
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              "
            v-bind="attrs"
            v-on="on"
          >
            {{ label }}
          </div>
        </template>
        <span>{{ label }}</span>
      </v-tooltip>
    </div>
    <div
      class="mr-3"
      style="width: 25%"
    >
      <v-select
        :value="operation"
        :items="operations"
        dense
        outlined
        hide-details
        @input="updateOperation($event)"
      />
    </div>
    <div
      style="width: 30%"
    >
      <v-text-field
        :value="value"
        type="number"
        :step="step"
        dense
        outlined
        hide-details
        @input="updateValue($event)"
      >
        0
      </v-text-field>
    </div>
  </div>
</template>

<script>

export default {

  props: {
    label: {
      type: String,
      required: true,
    },
    operation: {
      type: String,
      required: true,
    },
    value: {
      type: Number,
      required: true,
    },
    step: {
      type: Number,
      default: 0.1,
    },
  },

  data() {
    return {
      operations: [
        {
          text: 'Tất cả',
          value: '',
        },
        {
          text: '>=',
          value: 'gte',
        },
        {
          text: '<=',
          value: 'lte',
        },
        {
          text: '>',
          value: 'gt',
        },
        {
          text: '<',
          value: 'lt',
        },
        {
          text: '=',
          value: 'eq',
        },
      ],
    }
  },

  methods: {
    updateValue(value) {
      this.$emit('value', parseFloat(value))
    },

    updateOperation(value) {
      this.$emit('operation', value)
    },
  },
}

</script>
